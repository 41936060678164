import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  CustomerDetailsAttributes,
  CustomerState,
  APIError,
  ChangeEmailBody,
  CustomerUpdate,
  CustomerAttributes,
  Customer,
  CustomerFilter,
  CustomerType,
  OrganizationCustomerCreate,
  Pagination
} from 'models';
import * as services from 'services';
import { showErrorToast } from 'utils/toast';
import type { RootState } from 'store/store';

export const defaultState: CustomerState = {
  list: {
    data: null,
    isLoading: false,
    error: null,
    filters: {
      active: false,
      customerType: CustomerType.IndividualCustomer
    }
  },
  details: {
    data: null,
    isLoading: false,
    error: null,
    pagination: {
      isLoading: false,
      limit: 24,
      cursor: null
    },
    deleteRegistrations: {
      timeStamp: null,
      isLoading: false,
      error: null
    },
    changeEmail: {
      newEmail: null,
      isSuccess: false,
      isLoading: false,
      error: null
    },
    update: {
      isSuccess: false,
      isLoading: false,
      error: null
    },
    confirmDeleteCustomer: {
      data: null,
      isSuccess: false,
      isLoading: false,
      error: null
    }
  },
  create: {
    data: null,
    isSuccess: false,
    isLoading: false,
    error: null
  }
};

// Export slice
export const customerSlice = createSlice({
  name: 'customer',
  initialState: defaultState,
  reducers: {
    getCustomerListInit: (state) => {
      state.list.isLoading = true;
      state.list.error = null;
    },
    getCustomerListSuccess: (state, action: PayloadAction<Customer[]>) => {
      state.list.isLoading = false;
      state.list.error = null;
      state.list.data = action.payload;
    },
    getCustomerListFailure: (state, action: PayloadAction<APIError>) => {
      state.list.data = [];
      state.list.isLoading = false;
      state.list.error = action.payload;
    },
    setCustomerListFilters: (state, action: PayloadAction<boolean>) => {
      state.list.filters.active = action.payload;
    },
    setCustomerTypeFilter: (state, action: PayloadAction<CustomerType>) => {
      state.list.filters.customerType = action.payload;
    },
    resetCustomerListState: (state) => {
      state.list = defaultState.list;
    },
    getCustomerDetailsInit: (state) => {
      state.details.isLoading = true;
      state.details.error = null;
    },
    getCustomerDetailsSuccess: (
      state,
      action: PayloadAction<CustomerDetailsAttributes>
    ) => {
      state.details.isLoading = false;
      state.details.data = action.payload;
      state.details.pagination.cursor = action.payload.nextCursor;
    },
    getCustomerDetailsFailure: (state, action: PayloadAction<APIError>) => {
      state.details.isLoading = false;
      state.details.error = action.payload;
    },
    loadMoreCustomerProductRegistrationsInit: (state) => {
      state.details.pagination.isLoading = true;
      state.details.error = null;
    },
    loadMoreCustomerProductRegistrationsSuccess: (
      state,
      action: PayloadAction<CustomerDetailsAttributes>
    ) => {
      state.details.pagination.isLoading = false;
      state.details.pagination.cursor = action.payload.nextCursor;

      if (state.details.data) {
        state.details.data.productRegistrations = [
          ...state.details.data.productRegistrations,
          ...action.payload.productRegistrations
        ];
      }
    },
    loadMoreCustomerProductRegistrationsFailure: (
      state,
      action: PayloadAction<APIError>
    ) => {
      state.details.pagination.isLoading = false;
      state.details.error = action.payload;
    },
    resetCustomerDetailsState: (state) => {
      state.details = defaultState.details;
    },
    deleteRegistrationsInit: (state) => {
      state.details.deleteRegistrations.isLoading = true;
      state.details.deleteRegistrations.error = null;
    },
    deleteRegistrationsSuccess: (state) => {
      state.details.deleteRegistrations.timeStamp = Date.now();
      state.details.deleteRegistrations.isLoading = false;
    },
    deleteRegistrationsFailure: (state, action: PayloadAction<APIError>) => {
      state.details.deleteRegistrations.timeStamp = null;
      state.details.deleteRegistrations.isLoading = false;
      state.details.deleteRegistrations.error = action.payload;
    },
    changeEmailInit: (state) => {
      state.details.changeEmail.isSuccess = false;
      state.details.changeEmail.isLoading = true;
      state.details.changeEmail.error = null;
    },
    changeEmailSuccess: (state, action: PayloadAction<string>) => {
      state.details.changeEmail.isSuccess = true;
      state.details.changeEmail.isLoading = false;
      state.details.changeEmail.newEmail = action.payload;
    },
    changeEmailFailure: (state, action: PayloadAction<APIError>) => {
      state.details.changeEmail.isLoading = false;
      state.details.changeEmail.error = action.payload;
    },
    confirmDeleteCustomerInit: (state) => {
      state.details.confirmDeleteCustomer.isSuccess = false;
      state.details.confirmDeleteCustomer.isLoading = true;
      state.details.confirmDeleteCustomer.error = null;
    },
    confirmDeleteCustomerSuccess: (state) => {
      state.details.confirmDeleteCustomer.isSuccess = true;
      state.details.confirmDeleteCustomer.isLoading = false;
    },
    confirmDeleteCustomerFailure: (state, action: PayloadAction<APIError>) => {
      state.details.confirmDeleteCustomer.isLoading = false;
      state.details.confirmDeleteCustomer.error = action.payload;
    },
    updateCustomerInit: (state) => {
      state.details.update.isSuccess = false;
      state.details.update.isLoading = true;
      state.details.update.error = null;
    },
    updateCustomerSuccess: (
      state,
      action: PayloadAction<CustomerAttributes>
    ) => {
      state.details.update.isLoading = false;
      if (state.details.data) {
        state.details.update.isSuccess = true;
        state.details.data.customer = action.payload;
      }
    },
    updateCustomerFailure: (state, action: PayloadAction<APIError>) => {
      state.details.update.isLoading = false;
      state.details.update.error = action.payload;
    },
    createCustomerInit: (state) => {
      state.create.isSuccess = false;
      state.create.isLoading = true;
      state.create.error = null;
    },
    createCustomerSuccess: (
      state,
      action: PayloadAction<CustomerAttributes>
    ) => {
      state.create.isLoading = false;
      state.create.isSuccess = true;
      state.create.data = action.payload;
    },
    createCustomerFailure: (state, action: PayloadAction<APIError>) => {
      state.create.isLoading = false;
      state.create.error = action.payload;
    },
    resetCustomerCreateState: (state) => {
      state.create = defaultState.create;
    }
  }
});

// Export selectors
export const customerListSelector = (state: RootState) => state.customer.list;
export const customerDetailsSelector = (state: RootState) =>
  state.customer.details;
export const customerCreateSelector = (state: RootState) =>
  state.customer.create;

// Export actions
export const {
  getCustomerListInit,
  getCustomerListSuccess,
  getCustomerListFailure,
  setCustomerListFilters,
  setCustomerTypeFilter,
  resetCustomerListState,
  getCustomerDetailsInit,
  getCustomerDetailsSuccess,
  getCustomerDetailsFailure,
  loadMoreCustomerProductRegistrationsInit,
  loadMoreCustomerProductRegistrationsSuccess,
  loadMoreCustomerProductRegistrationsFailure,
  resetCustomerDetailsState,
  deleteRegistrationsInit,
  deleteRegistrationsSuccess,
  deleteRegistrationsFailure,
  changeEmailInit,
  changeEmailSuccess,
  changeEmailFailure,
  confirmDeleteCustomerInit,
  confirmDeleteCustomerSuccess,
  confirmDeleteCustomerFailure,
  createCustomerInit,
  createCustomerSuccess,
  createCustomerFailure,
  resetCustomerCreateState,
  updateCustomerInit,
  updateCustomerSuccess,
  updateCustomerFailure
} = customerSlice.actions;

// Export reducer
export const customerReducer = customerSlice.reducer;

// Export thunk
export function findCustomersByFilter(filter: CustomerFilter[]) {
  return async (dispatch: Dispatch) => {
    dispatch(getCustomerListInit());
    try {
      const customers = await services.findCustomersByFilter(filter);
      dispatch(getCustomerListSuccess(customers));
    } catch (e: any) {
      dispatch(getCustomerListFailure(e));
    }
  };
}

export function fetchCustomer(customerId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getCustomerDetailsInit());
    try {
      const details =
        await services.findCustomerProductRegistrations(customerId);
      dispatch(getCustomerDetailsSuccess(details));
    } catch (e: any) {
      dispatch(getCustomerDetailsFailure(e));
    }
  };
}

export function loadMoreCustomerProductRegistrations(
  customerId: string,
  pagination: Pagination
) {
  return async (dispatch: Dispatch) => {
    dispatch(loadMoreCustomerProductRegistrationsInit());
    try {
      const details = await services.findCustomerProductRegistrations(
        customerId,
        pagination
      );
      dispatch(loadMoreCustomerProductRegistrationsSuccess(details));
    } catch (e: any) {
      dispatch(loadMoreCustomerProductRegistrationsFailure(e));
    }
  };
}

export function deleteCustomerProductRegistrations(
  customerId: string,
  registrationIds: string[]
) {
  return async (dispatch: Dispatch) => {
    dispatch(deleteRegistrationsInit());
    try {
      await services.deleteCustomerProductRegistrations(
        customerId,
        registrationIds
      );
      dispatch(deleteRegistrationsSuccess());
    } catch (e: any) {
      dispatch(deleteRegistrationsFailure(e));
      showErrorToast(e.title);
    }
  };
}

export function createCustomer(data: OrganizationCustomerCreate) {
  return async (dispatch: Dispatch) => {
    dispatch(createCustomerInit());
    try {
      const response = await services.createCustomer(data);
      const { id, attributes } = response.data;
      dispatch(
        createCustomerSuccess({
          ...attributes,
          customerId: id
        })
      );
    } catch (e: any) {
      dispatch(createCustomerFailure(e));
      showErrorToast(e.title);
    }
  };
}

export function deleteCustomer(customerId: string, deleteReason: string) {
  return async (dispatch: Dispatch) => {
    dispatch(confirmDeleteCustomerInit());
    try {
      await services.confirmDeleteCustomer(customerId, deleteReason);
      dispatch(confirmDeleteCustomerSuccess());
    } catch (e: any) {
      dispatch(confirmDeleteCustomerFailure(e));
      showErrorToast(e.title);
    }
  };
}

export function requestChangeEmail(customerId: string, body: ChangeEmailBody) {
  return async (dispatch: Dispatch) => {
    dispatch(changeEmailInit());
    try {
      await services.requestChangeCustomerEmail(customerId, body);
      dispatch(changeEmailSuccess(body.newEmail));
    } catch (e: any) {
      dispatch(changeEmailFailure(e));
      showErrorToast(e.title);
    }
  };
}

export function updateCustomerData(customerId: string, update: CustomerUpdate) {
  return async (dispatch: Dispatch) => {
    dispatch(updateCustomerInit());
    try {
      const response = await services.updateCustomerById(customerId, update);
      const { id, attributes } = response.data;
      dispatch(
        updateCustomerSuccess({
          ...attributes,
          customerId: id
        })
      );
    } catch (e: any) {
      dispatch(updateCustomerFailure(e));
      showErrorToast(e.title);
    }
  };
}
