import { FC, useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { ProductFilterObject } from 'models';
import Button from 'components/UI/Button';

import { Form, Row, Input, InputLarge, ButtonGrid, Divider } from './styled';

type Props = {
  onSearch: (values: ProductFilterObject) => void;
  onReset: () => void;
};

const SearchProductsForm: FC<Props> = ({ onSearch, onReset }) => {
  const intl = useIntl();
  const { register, handleSubmit, watch, reset } = useForm<ProductFilterObject>(
    {
      defaultValues: {
        pnc: '',
        serialNumber: '',
        husqvarnaId: ''
      }
    }
  );

  // Watch fields
  const [pnc, serialNumber, husqvarnaId] = watch([
    'pnc',
    'serialNumber',
    'husqvarnaId'
  ]);

  // Validation
  const pncAndSerial = useMemo(
    () => Boolean(pnc && serialNumber),
    [pnc, serialNumber]
  );
  const pncOrSerial = useMemo(
    () => Boolean(pnc || serialNumber),
    [pnc, serialNumber]
  );

  // Submit
  const onSubmit: SubmitHandler<ProductFilterObject> = useCallback(onSearch, [
    onSearch
  ]);

  // Clear
  const onClear = useCallback(() => {
    onReset();
    reset();
  }, [onReset, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Input
          {...register('pnc', {
            required: pncOrSerial
          })}
          className="input small"
          placeholder={`${intl.formatMessage({ id: 'product_details.pnc' })}`}
          disabled={!!husqvarnaId}
        />
        <Divider>+</Divider>
        <Input
          {...register('serialNumber', {
            required: pncOrSerial
          })}
          className="input small"
          placeholder={`${intl.formatMessage({
            id: 'product_details.serial'
          })}`}
          disabled={!!husqvarnaId}
        />
        <Divider>or</Divider>
        <InputLarge
          {...register('husqvarnaId', {
            required: !!husqvarnaId
          })}
          className="input large"
          placeholder={`${intl.formatMessage({ id: 'product_details.hid' })}`}
          disabled={pncOrSerial}
        />
      </Row>
      <ButtonGrid>
        <Button
          onClick={onClear}
          backgroundColor="transparent"
          color="primary"
          marginRight
        >
          <FormattedMessage id="search.clear" />
        </Button>
        <Button submit disabled={!pncAndSerial && !husqvarnaId}>
          <FormattedMessage id="search.btn" />
        </Button>
      </ButtonGrid>
    </Form>
  );
};

export default SearchProductsForm;
