import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  ProductFilter,
  APIError,
  ProductRegistrationState,
  RegisterProductsCustomer,
  ProductFilterObject,
  RegisterProductListItem
} from 'models';
import { findProductInfo, registerProductsCustomer } from 'services';
import { showErrorToast, showInfoToast, showSuccessToast } from 'utils/toast';
import { createFilters } from 'utils/filtering';
import type { RootState } from 'store/store';
import { getProductText } from 'utils/product';

export const defaultState: ProductRegistrationState = {
  data: null,
  params: null,
  isSuccess: false,
  isLoading: false,
  error: null
};

// Export slice
export const productRegistrationSlice = createSlice({
  name: 'product-registration',
  initialState: defaultState,
  reducers: {
    productSearch: (state, action: PayloadAction<ProductFilterObject>) => {
      state.isLoading = true;
      state.error = null;
      state.params = action.payload;
    },
    productSearchSuccess: (
      state,
      action: PayloadAction<RegisterProductListItem | null>
    ) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    productSearchFailure: (state, action: PayloadAction<APIError>) => {
      state.data = null;
      state.isLoading = false;
      state.error = action.payload;
    },
    productRegistration: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    productRegistrationSuccess: (state) => {
      state.data = null;
      state.isSuccess = true;
      state.isLoading = false;
    },
    productRegistrationFailure: (state, action: PayloadAction<APIError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetProductRegistration: () => {
      return defaultState;
    }
  }
});

// Export selectors
export const productRegistrationSelector = (state: RootState) =>
  state.productRegistration;

// Export actions
export const {
  productSearch,
  productSearchSuccess,
  productSearchFailure,
  productRegistration,
  productRegistrationSuccess,
  productRegistrationFailure,
  resetProductRegistration
} = productRegistrationSlice.actions;

// Export reducer
export const productRegistrationReducer = productRegistrationSlice.reducer;

// Export thunk
export const searchProductsToRegister =
  (values: ProductFilterObject) => async (dispatch: Dispatch) => {
    dispatch(productSearch(values));
    try {
      const filters = createFilters<ProductFilter>(values);
      const data = await findProductInfo(filters);
      dispatch(
        productSearchSuccess({
          imageUrl: data.imageUrl,
          imageId: data.imageId,
          title: getProductText(data),
          pnc: data.pnc,
          serialNumber: data.serialNumber,
          husqvarnaId: data.husqvarnaId
        })
      );
    } catch (e: any) {
      dispatch(productSearchFailure(e));
      showInfoToast(e.title);
    }
  };

export const registerProducts =
  (data: RegisterProductsCustomer, successMessage: string) =>
  async (dispatch: Dispatch) => {
    dispatch(productRegistration());
    try {
      await registerProductsCustomer(data);
      dispatch(productRegistrationSuccess());
      showSuccessToast(successMessage);
    } catch (e: any) {
      dispatch(productRegistrationFailure(e));
      showErrorToast(e.title);
    }
  };
