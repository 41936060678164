import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import * as routes from 'router/Routes';
import { ProductTreeAttributes } from 'models';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import Icon, { IconType } from 'components/UI/Icon';

type Props = {
  data: ProductTreeAttributes | null;
  iprId: string;
  isLoading?: boolean;
};

const ProductTreeCard: FC<Props> = ({ data, iprId, isLoading }) => {
  const navigate = useNavigate();

  // Link to product
  const onClick = useCallback(
    (iprId: string) => {
      navigate(routes.productDetailsLink(iprId));
    },
    [navigate]
  );

  // Render table
  const table = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }

    if (!data) {
      return (
        <EmptyState icon={IconType.Product} padding includeParams>
          <FormattedMessage id="product_details.tree_table_not_found" />
        </EmptyState>
      );
    }

    // Header
    const headerCells: HeaderCell[] = [
      {},
      {
        intl: 'product_details.product',
        sortable: false
      }
    ];

    return (
      <SortableTable
        sortIndex={4}
        headerCells={headerCells}
        bodyRows={[
          {
            icon: <Icon type={IconType.LayerFilled} />,
            type: data.type,
            link: data.iprId,
            active: data.iprId === iprId,
            indent: data.level
          },
          ...data.children.map((child) => ({
            icon: <Icon type={IconType.Layer} />,
            type: child.type,
            link: child.iprId,
            active: child.iprId === iprId,
            indent: child.level
          }))
        ]}
        emptyTextId="product_details.tree_table_not_found"
        onClick={onClick}
      />
    );
  }, [data, iprId, isLoading, onClick]);

  return (
    <Card>
      <CardHeader titleIntl="product_details.tree_table_title" />
      {table}
    </Card>
  );
};

export default ProductTreeCard;
