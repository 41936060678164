import styled from 'styled-components';

type Props = {
  $clickable: boolean;
};

export const Root = styled.th<Props>`
  color: ${({ theme }) => theme.colors.grey6};
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  padding: 10px 8px;

  &:first-child {
    padding-left: 16px;
  }

  > button {
    display: flex;
    align-items: center;
    background: none;
    padding: 0;
    border: none;
    margin: 0 auto 0 0;

    &:hover {
      ${({ $clickable }) => $clickable && 'cursor: pointer;'}
    }

    > p {
      width: auto;
      margin: 0;
      text-transform: uppercase;
      text-align: left;
    }
  }
`;
