import { FC, useCallback, useMemo, useState } from 'react';

import compareIcon from 'assets/vectors/compare-icon.svg';
import { AuditLog, AuditLogAttributes } from 'models';
import { SortOrder } from 'utils/array';
import { formatDate } from 'utils/date';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import IconButton from 'components/UI/IconButton';
import Loader from 'components/UI/Loader';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import UserAuditDiffModal from 'components/users/modals/UserAuditDiffModal';

const headerCells: HeaderCell[] = [
  {
    sortable: true,
    intl: 'user_details.audit_log_time'
  },
  {
    sortable: true,
    intl: 'user_details.audit_log_type'
  },
  {
    sortable: true,
    intl: 'user_details.audit_log_subtype'
  },
  {
    sortable: true,
    intl: 'user_details.audit_log_description'
  },
  {
    sortable: true,
    intl: 'user_details.audit_log_done_by'
  },
  {
    sortable: false,
    intl: 'user_details.audit_log_changes'
  }
];

type Props = {
  data: AuditLog[];
  isLoading: boolean;
};

const UserAuditLogCard: FC<Props> = ({ data, isLoading }) => {
  // State
  const [modalData, setModalData] = useState<AuditLogAttributes | null>(null);

  // Actions
  const onClose = useCallback(() => setModalData(null), []);
  const onOpen = useCallback(
    (attributes: AuditLogAttributes) => () => setModalData(attributes),
    []
  );

  // Table
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }

    // Body rows
    const bodyRows = data.map(({ attributes }) => ({
      time: formatDate(attributes.createdDate, 'yyyy-MM-dd HH:mm'),
      changeType: attributes.operationType || '-',
      changeSubType: attributes.operationSubtype || '-',
      description: attributes.description || '-',
      doneBy: attributes.userId || '-',
      button: (
        <IconButton onClick={onOpen(attributes)}>
          <img src={compareIcon} alt="Changes" />
        </IconButton>
      )
    }));

    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={bodyRows}
        emptyTextId="user_details.audit_log_empty"
        sortOrder={SortOrder.Desc}
      />
    );
  }, [onOpen, data, isLoading]);

  return (
    <Card>
      <CardHeader titleIntl="user_details.audit_log_title" />
      {content}
      <UserAuditDiffModal data={modalData} onClose={onClose} />
    </Card>
  );
};

export default UserAuditLogCard;
