import styled from 'styled-components';

type Props = {
  $isPressable: boolean;
  $padding?: boolean;
  $indent?: number;
  disabled?: boolean;
};

export const Root = styled.td<Props>`
  position: relative;
  cursor: ${({ $isPressable, disabled }) => {
    return $isPressable && !disabled ? 'pointer' : 'default';
  }};
  border-top: 1px solid ${({ theme }) => theme.colors.grey3};
  max-width: 250px;
  padding: ${({ $padding }) => ($padding ? '16px' : '0')} 8px;

  &:first-child {
    padding-left: ${({ $indent }) => ($indent ? `${$indent * 16}px` : '16px')};
  }

  &:last-child {
    padding-right: 16px;
  }
`;

export const ContentContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: ${({ $isPressable }) =>
    $isPressable ? 'flex-end' : 'inherit'};
`;

export const Content = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.grey9};
  font-size: 16px;
`;

export const Image = styled.img`
  max-height: 64px;
`;
