import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';

import { AuditLogAttribute } from 'models';
import { Column, Grid } from 'styles';
import {
  auditLogsSelector,
  serviceRecordSelector,
  productDetailsSelector,
  fetchProductByIprId,
  fetchServiceRecordsByIprId,
  fetchAuditLogs,
  fetchFactoryPin,
  resetAuditLogsState,
  resetServiceRecordState,
  removeWarrantyConditionByIprId,
  warrantyConditionSelector,
  fetchWarrantyConditionByIprId,
  connectivityDeviceSelector,
  fetchDevice,
  connectivitySoftwareUpdatesSelector,
  fetchSofwareUpdates,
  fetchProductTreeByIprId,
  productTreeSelector
} from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useValidateAzureEditProduct } from 'hooks/useValidateAzureEditProduct';
import * as routes from 'router/Routes';

import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import NoAccessState from 'components/UI/NoAccessState';
import { IconType } from 'components/UI/Icon';
import ProductNameCard from 'components/products/ProductNameCard';
import { ConfirmRemoveWarrantyModal } from 'components/products/ConfirmDeleteWarrantyModal';
import ProductContractCard from 'components/products/ProductContractCard';
import ProductWarrantyCard from 'components/products/ProductWarrantyCard';
import CustomerRelationCard from 'components/customers/CustomerRelationCard';
import ServiceRecordCard from 'components/servicerecords/ServiceRecordCard';
import AuditLogCard from 'components/auditlog/AuditLogCard';
import ViewFactoryPinModal from 'components/products/ViewFactoryPinModal';
import SoftwareUpdatesTable from 'components/products/SoftwareUpdatesCard';
import ProductDealerCard from 'components/products/ProductDealerCard';
import ProductTreeCard from 'components/products/ProductTreeCard';

const ProductDetailsPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { iprId } = useParams();

  // Redux state
  const tree = useAppSelector(productTreeSelector);
  const details = useAppSelector(productDetailsSelector);
  const connectivityDevice = useAppSelector(connectivityDeviceSelector);
  const softwareUpdates = useAppSelector(connectivitySoftwareUpdatesSelector);
  const warranty = useAppSelector(warrantyConditionSelector);
  const serviceRecord = useAppSelector(serviceRecordSelector);
  const auditLogs = useAppSelector(auditLogsSelector);

  // Access
  const canEdit = useValidateAzureEditProduct({
    allowAnySalesCompanyAdmin: true
  });

  // State
  const [viewFactoryPinModalOpen, setViewFactoryPinModalOpen] =
    useState<boolean>(false);
  const [removeWarrantyModalOpen, setRemoveWarrantyModalOpen] =
    useState<boolean>(false);

  // Top product iprId
  const topProduct = useMemo(() => tree.data, [tree.data]);

  // Modal actions
  const openRemoveWarrantyModal = useCallback(
    () => setRemoveWarrantyModalOpen(true),
    []
  );
  const closeViewFactoryPinModal = useCallback(
    () => setViewFactoryPinModalOpen(false),
    []
  );
  const closeRemoveWarrantyModal = useCallback(
    () => setRemoveWarrantyModalOpen(false),
    []
  );

  const onNavigateServicePlan = useCallback(() => {
    navigate(routes.productServicePlanLink(iprId));
  }, [navigate, iprId]);

  const openViewFactoryPinModal = useCallback(() => {
    setViewFactoryPinModalOpen(true);
    if (topProduct) {
      dispatch(fetchFactoryPin(topProduct.iprId));
    }
  }, [dispatch, topProduct]);

  const onRemoveWarranty = useCallback(() => {
    if (topProduct) {
      dispatch(removeWarrantyConditionByIprId(topProduct.iprId));
    }
  }, [dispatch, topProduct]);

  // Fetch audit logs
  const fetchAuditLogsByIprId = useCallback(() => {
    if (topProduct) {
      dispatch(
        fetchAuditLogs([
          {
            attribute: AuditLogAttribute.IprId,
            value: topProduct.iprId
          }
        ])
      );
    }
  }, [dispatch, topProduct]);

  // Fetch warranty
  const fetchWarrantyByIprId = useCallback(() => {
    if (topProduct) {
      dispatch(fetchWarrantyConditionByIprId(topProduct.iprId));
    }
  }, [dispatch, topProduct]);

  const refetchWarranty = useCallback(() => {
    fetchWarrantyByIprId();
    fetchAuditLogsByIprId();
  }, [fetchWarrantyByIprId, fetchAuditLogsByIprId]);

  // Fetch product
  useEffect(() => {
    if (iprId) {
      dispatch(fetchProductTreeByIprId(iprId));
      dispatch(fetchDevice(iprId));
      dispatch(fetchSofwareUpdates(iprId));
    }
    return () => {
      dispatch(resetAuditLogsState());
      dispatch(resetServiceRecordState());
    };
  }, [dispatch, iprId]);

  // Fetch top product data with product tree
  useEffect(() => {
    if (topProduct) {
      dispatch(fetchProductByIprId(topProduct.iprId));
      dispatch(fetchWarrantyConditionByIprId(topProduct.iprId));
      dispatch(fetchServiceRecordsByIprId(topProduct.iprId));
      fetchAuditLogsByIprId();
    }
  }, [dispatch, fetchAuditLogsByIprId, topProduct]);

  // Cards description
  const descriptionIntl = useMemo(
    () =>
      tree.data?.iprId !== iprId ? 'product_details.top_product' : undefined,
    [tree.data, iprId]
  );

  // Loading
  if (tree.isLoading) {
    return <Loader center padding />;
  }

  // No access
  if (!details.data && details.error?.status === 403) {
    return <NoAccessState />;
  }

  // Not found
  if (!iprId || !topProduct) {
    return (
      <EmptyState icon={IconType.Product} padding includeParams>
        <FormattedMessage id="product_details.not_found" />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <ProductNameCard
        iprId={iprId}
        canEdit={canEdit}
        productTree={tree.data}
        device={connectivityDevice.data}
        openViewFactoryPinModal={openViewFactoryPinModal}
        onNavigateServicePlan={onNavigateServicePlan}
      />
      <Grid>
        <Column $col={1} $marginRight>
          <ProductTreeCard
            iprId={iprId}
            data={tree.data}
            isLoading={tree.isLoading}
          />
          <ProductWarrantyCard
            iprId={topProduct.iprId}
            contracts={details.data?.serviceContracts}
            warranty={warranty.data}
            isLoading={warranty.isLoading || details.isLoading}
            descriptionIntl={descriptionIntl}
            onCreate={fetchAuditLogsByIprId}
            onRemove={openRemoveWarrantyModal}
          />
          <ProductDealerCard
            dealers={details.data?.dealers}
            warranty={warranty.data}
            isLoading={warranty.isLoading || details.isLoading}
            descriptionIntl={descriptionIntl}
          />
        </Column>
        <Column $col={2}>
          <CustomerRelationCard
            data={details.data?.productRegistrations}
            isLoading={details.isLoading}
            descriptionIntl={descriptionIntl}
          />
          <ProductContractCard
            contracts={details.data?.serviceContracts}
            isLoading={details.isLoading}
            descriptionIntl={descriptionIntl}
          />
          <SoftwareUpdatesTable softwareUpdates={softwareUpdates} />
          <ServiceRecordCard
            isLoading={serviceRecord.isLoading}
            data={serviceRecord.data}
            descriptionIntl={descriptionIntl}
          />
        </Column>
      </Grid>
      <AuditLogCard
        isLoading={auditLogs.isLoading}
        data={auditLogs.data}
        descriptionIntl={descriptionIntl}
      />
      <ViewFactoryPinModal
        open={viewFactoryPinModalOpen}
        close={closeViewFactoryPinModal}
        onFetch={fetchAuditLogsByIprId}
      />
      <ConfirmRemoveWarrantyModal
        open={removeWarrantyModalOpen}
        close={closeRemoveWarrantyModal}
        onFetch={refetchWarranty}
        onRemove={onRemoveWarranty}
      />
    </Fragment>
  );
};

export default ProductDetailsPage;
