import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useDiamUserAdminAccess } from 'hooks/useDiamUserAdminAccess';
import { useDisplayNames } from 'hooks/useDisplayNames';
import { User } from 'models';

import EditUserModal from 'components/users/modals/EditUserModal';
import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import InfoItem from 'components/UI/InfoItem';
import DropdownMenu from 'components/UI/DropdownMenu';
import IconButton from 'components/UI/IconButton';
import Icon, { IconType } from 'components/UI/Icon';

import { Container } from './styled';

type Props = {
  user: User;
};

const UserInfoCard: FC<Props> = ({ user }) => {
  const intl = useIntl();
  const diamUserAdminAccess = useDiamUserAdminAccess(user);
  const languageDisplayNames = useDisplayNames('language');
  const countryDisplayNames = useDisplayNames('region');

  const {
    firstName,
    lastName,
    postalAddress,
    address2,
    postalCode,
    city,
    country,
    language,
    telephoneNumber,
    passwordPolicy
  } = user.attributes;

  // State
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  // Actions
  const onClose = useCallback(() => setModalOpen(false), []);

  // Menu
  const menu = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: 'user_details.btn_edit_user'
        }),
        onClick: () => setModalOpen(true)
      }
    ];
  }, [intl]);

  const details = useMemo(() => {
    const items = [
      {
        id: 1,
        title: 'user_details.first_name',
        text: firstName
      },
      {
        id: 2,
        title: 'user_details.last_name',
        text: lastName
      },
      {
        id: 3,
        title: 'user_details.address',
        text: postalAddress
      },
      {
        id: 4,
        title: 'user_details.address2',
        text: address2
      },
      {
        id: 5,
        title: 'user_details.postal_code',
        text: postalCode
      },
      {
        id: 6,
        title: 'user_details.city',
        text: city
      },
      {
        id: 7,
        title: 'user_details.country',
        text: countryDisplayNames.of(country)
      },
      {
        id: 8,
        title: 'user_details.language',
        text: languageDisplayNames.of(language)
      },
      {
        id: 9,
        title: 'user_details.phone_number',
        text: telephoneNumber
      },
      {
        id: 10,
        title: 'user_details.password_policy',
        text: passwordPolicy.id
      }
    ];

    return items.map(({ id, title, text }) => (
      <InfoItem
        key={id}
        title={intl.formatMessage({ id: title })}
        value={text || '–'}
      />
    ));
  }, [
    firstName,
    lastName,
    postalAddress,
    address2,
    postalCode,
    city,
    countryDisplayNames,
    country,
    languageDisplayNames,
    language,
    telephoneNumber,
    passwordPolicy.id,
    intl
  ]);

  return (
    <Card>
      <CardHeader titleIntl="user_details.user_information">
        {diamUserAdminAccess && (
          <DropdownMenu menu={menu}>
            <IconButton>
              <Icon type={IconType.More} themeType="grey6" />
            </IconButton>
          </DropdownMenu>
        )}
      </CardHeader>
      <Container>{details}</Container>
      <EditUserModal user={user} open={modalOpen} onClose={onClose} />
    </Card>
  );
};

export default UserInfoCard;
