import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  ProductAttributes,
  ProductFilter,
  ProductState,
  ProductDetails,
  APIError,
  ProductTreeAttributes
} from 'models';
import {
  findProductRegistrations,
  findProductRegistrationsByIprId,
  findProductTreeByIprId,
  getFactoryPinByIprId
} from 'services';
import type { RootState } from 'store/store';

export const defaultState: ProductState = {
  list: {
    data: null,
    isLoading: false,
    error: null,
    filters: {
      active: false
    }
  },
  details: {
    data: null,
    isLoading: false,
    error: null
  },
  tree: {
    data: null,
    isLoading: false,
    isError: false,
    error: null
  },
  factoryPin: {
    data: null,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const productSlice = createSlice({
  name: 'product',
  initialState: defaultState,
  reducers: {
    getProductList: (state) => {
      state.list.isLoading = true;
      state.list.error = null;
    },
    getProductListSuccess: (
      state,
      action: PayloadAction<ProductAttributes[]>
    ) => {
      state.list.isLoading = false;
      state.list.error = null;
      state.list.data = action.payload;
    },
    getProductListFailure: (state, action: PayloadAction<APIError>) => {
      state.list.data = [];
      state.list.isLoading = false;
      state.list.error = action.payload;
    },
    setProductFilters: (state, action: PayloadAction<boolean>) => {
      state.list.filters.active = action.payload;
    },
    getProduct: (state) => {
      state.details.isLoading = true;
      state.details.error = null;
    },
    getProductSuccess: (state, action: PayloadAction<ProductDetails>) => {
      state.details.isLoading = false;
      state.details.data = action.payload;
    },
    getProductFailure: (state, action: PayloadAction<APIError>) => {
      state.details.isLoading = false;
      state.details.error = action.payload;
    },
    getProductTree: (state) => {
      state.tree.isLoading = true;
      state.tree.error = null;
    },
    getProductTreeSuccess: (
      state,
      action: PayloadAction<ProductTreeAttributes>
    ) => {
      state.tree.isLoading = false;
      state.tree.data = action.payload;
    },
    getProductTreeFailure: (state, action: PayloadAction<APIError>) => {
      state.tree.isLoading = false;
      state.tree.error = action.payload;
    },
    viewFactoryPinInit: (state) => {
      state.factoryPin.data = null;
      state.factoryPin.isLoading = true;
      state.factoryPin.isError = false;
      state.factoryPin.error = null;
    },
    viewFactoryPinSuccess: (state, action: PayloadAction<string>) => {
      state.factoryPin.data = action.payload;
      state.factoryPin.isLoading = false;
      state.factoryPin.isError = false;
      state.factoryPin.error = null;
    },
    viewFactoryPinFailure: (state, action: PayloadAction<APIError>) => {
      state.factoryPin.data = null;
      state.factoryPin.isLoading = false;
      state.factoryPin.isError = true;
      state.factoryPin.error = action.payload;
    },
    resetViewFactoryPinNumber: (state) => {
      state.factoryPin = defaultState.factoryPin;
    }
  }
});

// Export selectors
export const productListSelector = (state: RootState) => state.product.list;
export const productDetailsSelector = (state: RootState) =>
  state.product.details;
export const productTreeSelector = (state: RootState) => state.product.tree;
export const viewFactoryPinSelector = (state: RootState) =>
  state.product.factoryPin;

// Export actions
export const {
  getProductList,
  getProductListSuccess,
  getProductListFailure,
  setProductFilters,
  getProduct,
  getProductSuccess,
  getProductFailure,
  getProductTree,
  getProductTreeSuccess,
  getProductTreeFailure,
  viewFactoryPinInit,
  viewFactoryPinSuccess,
  viewFactoryPinFailure,
  resetViewFactoryPinNumber
} = productSlice.actions;

// Export reducer
export const productReducer = productSlice.reducer;

// Export thunk
export function fetchProductList(filters: ProductFilter[]) {
  return async (dispatch: Dispatch) => {
    dispatch(getProductList());
    try {
      const { product } = await findProductRegistrations(filters);
      dispatch(getProductListSuccess(product ? [product] : []));
    } catch (e: any) {
      dispatch(getProductListFailure(e));
    }
  };
}

export function fetchProductByIprId(iprId: string, languageCode?: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getProduct());
    try {
      const response = await findProductRegistrationsByIprId(
        iprId,
        languageCode
      );
      dispatch(getProductSuccess(response));
    } catch (e: any) {
      dispatch(getProductFailure(e));
    }
  };
}

export function fetchProductTreeByIprId(iprId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getProductTree());
    try {
      const response = await findProductTreeByIprId(iprId);
      dispatch(getProductTreeSuccess(response));
    } catch (e: any) {
      dispatch(getProductTreeFailure(e));
    }
  };
}

export function fetchFactoryPin(iprId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(viewFactoryPinInit());
    try {
      const response = await getFactoryPinByIprId(iprId);
      dispatch(viewFactoryPinSuccess(response.data.attributes.factorypin));
    } catch (e: any) {
      dispatch(viewFactoryPinFailure(e));
    }
  };
}
