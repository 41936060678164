import styled from 'styled-components';

type Props = {
  $active: boolean;
};

export const Root = styled.tr<Props>`
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.grey2 : 'transparent'};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey2};
  }
`;
