import { useMemo } from 'react';
import { authSelector, productDetailsSelector } from 'store';
import {
  getSalesCompanyCodesFromProductRegistrations,
  getUserRoles,
  validateAzureEditPermission
} from 'utils/roles';
import { useAppSelector } from './redux';

type Props = { allowAnySalesCompanyAdmin: boolean };

export const useValidateAzureEditProduct = ({
  allowAnySalesCompanyAdmin = false
}: Props) => {
  const { session } = useAppSelector(authSelector);
  const details = useAppSelector(productDetailsSelector);

  // Can edit
  return useMemo(() => {
    const roles = getUserRoles(session);

    const salesCompanyCodes = getSalesCompanyCodesFromProductRegistrations(
      details.data?.productRegistrations
    );

    return validateAzureEditPermission(
      roles.azure,
      salesCompanyCodes,
      allowAnySalesCompanyAdmin
    );
  }, [session, details, allowAnySalesCompanyAdmin]);
};
