import { FC } from 'react';
import { useNumberFormat } from 'hooks/useNumberFormat';
import TableDataCell from '../TableDataCell';

type ImageTextCell = {
  imageUrl: string;
  text: string;
};

// Check if entry has image
const hasImage = (obj: any): obj is ImageTextCell => {
  return (
    obj &&
    typeof obj === 'object' &&
    (typeof obj.imageUrl === 'string' || typeof obj.text === 'string')
  );
};

type Props = {
  name: string;
  value: any;
  indent?: number;
};

const TableBodyCell: FC<Props> = ({ name, value, indent }) => {
  const numberFormatter = useNumberFormat();

  if (hasImage(value)) {
    return (
      <TableDataCell imageUrl={value.imageUrl}>{value.text}</TableDataCell>
    );
  }

  if (
    name === 'link' ||
    name === 'disabled' ||
    name === 'active' ||
    name === 'indent'
  ) {
    return null;
  }

  if (name === 'imageUrl') {
    return <TableDataCell imageUrl={value} indent={indent} />;
  }

  if (typeof value === 'number') {
    return (
      <TableDataCell indent={indent}>
        {numberFormatter.format(value)}
      </TableDataCell>
    );
  }

  return <TableDataCell indent={indent}>{value ?? '–'}</TableDataCell>;
};

export default TableBodyCell;
