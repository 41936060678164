import { apiFetch, createFilterParams, getFilterObject } from 'utils/fetch';
import {
  CustomerResponse,
  CustomerDetailsResponse,
  CustomerDetailsAttributes,
  ChangeEmailBody,
  CustomerUpdate,
  Customer,
  CustomerFilter,
  CustomersResponse,
  CustomerFilterAttribute,
  OrganizationCustomerCreate,
  Pagination
} from 'models';
import * as config from 'config';

export async function findCustomersByFilter(
  customerFilters: CustomerFilter[]
): Promise<Customer[]> {
  const {
    email,
    customerType,
    customerId,
    dunsNumber,
    companyName,
    customerNumber,
    salesCompanyCode,
    primaryContactEmail,
    onboardingSource
  } = getFilterObject(customerFilters);
  const filters = [];

  if (email && customerType) {
    filters.push(
      {
        attribute: CustomerFilterAttribute.Email,
        value: email
      },
      {
        attribute: CustomerFilterAttribute.CustomerType,
        value: customerType
      }
    );
  }

  if (customerId) {
    filters.push({
      attribute: CustomerFilterAttribute.CustomerId,
      value: customerId
    });
  }

  if (dunsNumber) {
    filters.push({
      attribute: CustomerFilterAttribute.DunsNumber,
      value: dunsNumber
    });
  }

  if (companyName) {
    filters.push({
      attribute: CustomerFilterAttribute.CompanyName,
      value: companyName
    });
  }

  if (customerNumber && salesCompanyCode) {
    filters.push(
      {
        attribute: CustomerFilterAttribute.CustomerNumber,
        value: customerNumber
      },
      {
        attribute: CustomerFilterAttribute.SalesCompanyCode,
        value: salesCompanyCode
      }
    );
  }

  if (primaryContactEmail) {
    filters.push({
      attribute: CustomerFilterAttribute.PrimaryContactEmail,
      value: primaryContactEmail
    });
  }

  if (onboardingSource) {
    filters.push({
      attribute: CustomerFilterAttribute.OnboardingSource,
      value: onboardingSource
    });
  }

  const url = createFilterParams(`${config.backendApi}/customers`, filters);
  const json = await apiFetch<CustomersResponse>(url);

  return json?.data || [];
}

export async function findCustomerProductRegistrations(
  customerId: string,
  pagination?: Pagination
): Promise<CustomerDetailsAttributes> {
  let url = `${config.backendApi}/customers/${customerId}/product-registrations`;

  if (pagination?.limit) {
    url += `?limit=${pagination.limit}`;
  }
  if (pagination?.cursor) {
    url += `&cursor=${pagination.cursor}`;
  }

  const json = await apiFetch<CustomerDetailsResponse>(url);

  return json.data.attributes;
}

export async function deleteCustomerProductRegistrations(
  customerId: string,
  registrationIds: string[]
): Promise<void> {
  const url = `${config.backendApi}/customers/${customerId}/product-registrations`;

  return apiFetch<any>(url, {
    method: 'DELETE',
    body: JSON.stringify({
      data: registrationIds.map((id) => {
        return { id, type: 'registration' };
      })
    })
  });
}

export async function confirmDeleteCustomer(
  customerId: string,
  reason: string
): Promise<void> {
  const url = `${config.backendApi}/customers/${customerId}/confirm-delete-customer`;

  return apiFetch<any>(url, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'customer',
        attributes: {
          reason
        }
      }
    })
  });
}

export async function requestChangeCustomerEmail(
  customerId: string,
  body: ChangeEmailBody
): Promise<void> {
  const { newEmail, oldEmail, meta } = body;
  const url = `${config.backendApi}/customers/${customerId}/change-email`;

  return apiFetch<any>(url, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'customers',
        attributes: { newEmail, oldEmail }
      },
      meta
    })
  });
}

export async function updateCustomerById(
  customerId: string,
  customerUpdate: CustomerUpdate
): Promise<CustomerResponse> {
  const url = `${config.backendApi}/customers/${customerId}`;

  return apiFetch<any>(url, {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: 'customers',
        attributes: {
          ...customerUpdate
        }
      }
    })
  });
}

export async function createCustomer(
  data: OrganizationCustomerCreate
): Promise<CustomerResponse> {
  return apiFetch<any>(`${config.backendApi}/customers`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'customers',
        attributes: {
          ...data
        }
      }
    })
  });
}
