import { FC } from 'react';
import IconPath, { IconType } from './IconPath';
import { ThemeColor } from 'styles';

export type IconSize =
  | 'small'
  | 'medium'
  | 'large'
  | 'extraLarge'
  | 'extraExtraLarge';

export const iconSizeMap = {
  small: '16',
  medium: '24',
  large: '36',
  extraLarge: '48',
  extraExtraLarge: '96'
};

type Props = {
  type: IconType;
  themeType?: ThemeColor;
  color?: string;
  size?: IconSize;
};

const Icon: FC<Props> = ({ type, themeType, color, size = 'medium' }) => {
  return (
    <svg
      data-testid="svg-icon"
      width={iconSizeMap[size]}
      height={iconSizeMap[size]}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <IconPath type={type} themeType={themeType} color={color} />
      </g>
    </svg>
  );
};

export default Icon;
export { IconType } from './IconPath';
