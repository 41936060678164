import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { DealerAttributes, Warranty } from 'models';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import InfoItem from 'components/UI/InfoItem';

import { DetailGroup } from './styled';
import Loader from 'components/UI/Loader';

type Props = {
  dealers?: DealerAttributes[];
  warranty: Warranty | null;
  isLoading: boolean;
  descriptionIntl?: string;
};

const ProductDealerCard: FC<Props> = ({
  dealers = [],
  warranty,
  isLoading,
  descriptionIntl
}) => {
  const intl = useIntl();

  // Dealer Id
  const dealerId = useMemo(() => {
    if (warranty) {
      const { companyCode, customerNo } = warranty.attributes;

      if (companyCode && customerNo) {
        return `${companyCode}-${customerNo}`;
      }
    }
    if (!dealers.length) {
      return null;
    }
    const { companyCode, customerNumber } = dealers[0];
    return `${companyCode}-${customerNumber}`;
  }, [warranty, dealers]);

  // Dealer address
  const dealerAddress = useMemo(() => {
    if (!dealers.length || !dealers[0].address) {
      return null;
    }
    const { street, zipCity, country } = dealers[0].address;

    let addressLine = `${street}, ${zipCity}`;
    if (country) {
      addressLine += `, ${country}`;
    }
    return addressLine;
  }, [dealers]);

  // Content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader center padding />;
    }

    const detailList = [
      {
        id: 1,
        title: 'product_details.dealer_id',
        text: dealerId
      },
      {
        id: 2,
        title: 'product_details.dealer_name',
        text: dealers?.[0]?.name
      },
      {
        id: 3,
        title: 'product_details.dealer_address',
        text: dealerAddress
      }
    ];

    return (
      <DetailGroup>
        {detailList.map(({ id, title, text }) => (
          <InfoItem
            key={id}
            title={intl.formatMessage({ id: title })}
            value={text || '—'}
          />
        ))}
      </DetailGroup>
    );
  }, [intl, dealerId, dealerAddress, dealers, isLoading]);

  return (
    <Card>
      <CardHeader
        titleIntl="product_details.dealer_title"
        descriptionIntl={descriptionIntl}
      />
      {content}
    </Card>
  );
};

export default ProductDealerCard;
