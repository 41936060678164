import { FC, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { CustomerAcceptance } from 'models';
import { formatDate } from 'utils/date';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import Loader from 'components/UI/Loader';
import EditModal from 'components/UI/EditModal';
import IconButton from 'components/UI/IconButton';
import Button from 'components/UI/Button';
import Icon, { IconType } from 'components/UI/Icon';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';

import { ButtonGrid } from './styled';

const headerCells: HeaderCell[] = [
  {
    sortable: true,
    intl: 'customers.acceptance_table_type'
  },
  {
    sortable: true,
    intl: 'customers.acceptance_table_accepted'
  },
  {
    sortable: false,
    intl: 'customers.table_edit'
  }
];

type RemoveModal = {
  open: boolean;
  id: string;
};

type Props = {
  data: CustomerAcceptance[];
  isLoading: boolean;
  deleteAcceptance: (id: string) => void;
  canEdit: boolean;
};

const CustomerAcceptanceCard: FC<Props> = ({
  data,
  deleteAcceptance,
  isLoading,
  canEdit
}) => {
  // State
  const [modal, setModal] = useState<RemoveModal>({ open: false, id: '' });

  // Actions
  const closeModal = () => setModal({ open: false, id: '' });
  const onDelete = () => {
    deleteAcceptance(modal.id);
    closeModal();
  };

  // Render
  const table = useMemo(() => {
    // Body
    const bodyRows = data.map(({ attributes }) => ({
      type: attributes.contractVariantId,
      accepted: formatDate(attributes.dateAccepted),
      editButton: canEdit ? (
        <IconButton
          onClick={() =>
            setModal({ open: true, id: attributes.contractVariantId })
          }
        >
          <Icon type={IconType.Remove} />
        </IconButton>
      ) : (
        '–'
      )
    }));

    if (isLoading) {
      return <Loader center />;
    }
    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={bodyRows}
        emptyTextId="customer_details.acceptance_empty"
      />
    );
  }, [isLoading, data, canEdit]);

  return (
    <Card>
      <CardHeader titleIntl="customer_details.acceptance_title" />
      {table}
      <EditModal
        title="modal.remove_acceptance_title"
        open={modal.open}
        close={closeModal}
      >
        <div>
          <FormattedMessage id="modal.remove_acceptance_text" />
          <ButtonGrid>
            <Button
              marginRight
              onClick={closeModal}
              backgroundColor="transparent"
              color="primary"
            >
              <FormattedMessage id="modal.btn_cancel" />
            </Button>
            <Button onClick={onDelete}>
              <FormattedMessage id="modal.btn_remove" />
            </Button>
          </ButtonGrid>
        </div>
      </EditModal>
    </Card>
  );
};

export default CustomerAcceptanceCard;
