import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useValidateAzureEditContract } from 'hooks/useValidateAzureEditContract';

import {
  auditLogsSelector,
  contractDetailsSelector,
  fetchAuditLogs,
  fetchServiceContract,
  fetchServiceRecordsByIprId,
  localizationSelector,
  resetAuditLogsState,
  resetContractDetails,
  resetServiceRecordState,
  serviceRecordSelector
} from 'store';

import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import NoAccessState from 'components/UI/NoAccessState';
import { IconType } from 'components/UI/Icon';

import AuditLogCard from 'components/auditlog/AuditLogCard';
import ProductRelationCard from 'components/products/ProductRelationCard';
import CustomerRelationCard from 'components/customers/CustomerRelationCard';
import EditServiceContractStatusModal from 'components/servicecontracts/EditServiceContractStatusModal';
import MoveServiceContractModal from 'components/servicecontracts/MoveServiceContractModal';
import ServiceContractNameCard from 'components/servicecontracts/ServiceContractNameCard';
import ServiceContractPaymentCard from 'components/servicecontracts/ServiceContractPaymentCard';
import ServiceRecordCard from 'components/servicerecords/ServiceRecordCard';
import SendServiceContractEmailModal from 'components/servicecontracts/SendServiceContractEmailModal';
import TermsAndConditionsCard from 'components/servicecontracts/TermsAndConditionsCard';

import { useAzureAccess } from 'hooks/useAzureAccess';
import { AuditLogAttribute } from 'models';
import { Column, Grid } from 'styles';

const ServiceContractDetailsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { contractId } = useParams();
  const { data, error, isLoading } = useAppSelector(contractDetailsSelector);
  const auditLogs = useAppSelector(auditLogsSelector);
  const serviceRecord = useAppSelector(serviceRecordSelector);
  const { locale } = useAppSelector(localizationSelector);

  // State
  const [statusModalOpen, setStatusModalOpen] = useState<boolean>(false);
  const [sendMailModalOpen, setSendMailModalOpen] = useState<boolean>(false);
  const [moveModalOpen, setMoveModalOpen] = useState<boolean>(false);

  // Hook
  const { isGlobalAdmin } = useAzureAccess();
  const canEdit = useValidateAzureEditContract();

  // Fetch service contract and state reset
  const fetchServiceContractDetails = useCallback(() => {
    if (contractId) {
      dispatch(fetchServiceContract(contractId));
    }
  }, [dispatch, contractId]);

  useEffect(() => {
    fetchServiceContractDetails();

    return () => {
      dispatch(resetContractDetails());
    };
  }, [dispatch, fetchServiceContractDetails]);

  // Fetch service record and state reset
  useEffect(() => {
    if (data) {
      dispatch(fetchServiceRecordsByIprId(data.attributes.product.iprId));
    }

    return () => {
      dispatch(resetServiceRecordState());
    };
  }, [dispatch, data]);

  // Fetch audit logs and state reset
  const fetchAuditLogsByServiceContractId = useCallback(() => {
    if (contractId) {
      dispatch(
        fetchAuditLogs([
          {
            attribute: AuditLogAttribute.ServiceContractId,
            value: contractId
          }
        ])
      );
    }
  }, [dispatch, contractId]);

  useEffect(() => {
    fetchAuditLogsByServiceContractId();

    return () => {
      dispatch(resetAuditLogsState());
    };
  }, [dispatch, fetchAuditLogsByServiceContractId]);

  // Modal actions
  const closeStatusModal = useCallback(() => setStatusModalOpen(false), []);
  const openStatusModal = useCallback(() => setStatusModalOpen(true), []);
  const closeSendMailModal = useCallback(() => setSendMailModalOpen(false), []);
  const openSendMailModal = useCallback(() => setSendMailModalOpen(true), []);
  const closeMoveModal = useCallback(() => setMoveModalOpen(false), []);
  const openMoveModal = useCallback(() => setMoveModalOpen(true), []);

  if (isLoading) {
    return <Loader center padding />;
  }

  if (!data && error?.status === 403) {
    return <NoAccessState />;
  }

  if (!data) {
    return (
      <EmptyState icon={IconType.Contract} padding includeParams>
        <FormattedMessage id="contract_details.not_found" />
      </EmptyState>
    );
  }

  const { serviceContract, customer, product, marketName } = data.attributes;
  const { termsAndConditions } = serviceContract;

  return (
    <Fragment>
      <ServiceContractNameCard
        canEdit={canEdit}
        isGlobalAdmin={isGlobalAdmin}
        contract={serviceContract}
        marketName={marketName}
        openStatusModal={openStatusModal}
        openSendMailModal={openSendMailModal}
        openMoveModal={openMoveModal}
      />
      <Grid>
        <Column $col={2} $marginRight>
          <ServiceContractPaymentCard contract={serviceContract} />
        </Column>
        <Column $col={3}>
          <CustomerRelationCard data={customer ? [{ customer }] : []} />
          <ProductRelationCard products={[product]} />
          <TermsAndConditionsCard
            termsAndConditions={termsAndConditions ?? []}
            locale={locale}
          />
        </Column>
      </Grid>
      <Grid>
        <Column>
          <ServiceRecordCard
            isLoading={serviceRecord.isLoading}
            data={serviceRecord.data}
          />
          <AuditLogCard isLoading={auditLogs.isLoading} data={auditLogs.data} />
        </Column>
      </Grid>
      <SendServiceContractEmailModal
        open={sendMailModalOpen}
        contractId={serviceContract.id}
        close={closeSendMailModal}
      />
      <EditServiceContractStatusModal
        open={statusModalOpen}
        contract={serviceContract}
        fetchAuditLogs={fetchAuditLogsByServiceContractId}
        close={closeStatusModal}
      />
      <MoveServiceContractModal
        open={moveModalOpen}
        contract={serviceContract}
        product={product}
        fetchServiceContractDetails={fetchServiceContractDetails}
        fetchAuditLogs={fetchAuditLogsByServiceContractId}
        close={closeMoveModal}
      />
    </Fragment>
  );
};

export default ServiceContractDetailsPage;
