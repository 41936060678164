import { parse } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';

import { EmailBounce, EmailBounceAttributes } from 'models';
import { SortOrder } from 'utils/array';
import { formatDate } from 'utils/date';

import ArrowIcon from 'components/UI/Icon/ArrowIcon';
import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import IconButton from 'components/UI/IconButton';
import Loader from 'components/UI/Loader';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import { userEmailBouncesDiagnosticInfoCharacterLimit } from 'utils/constants';
import { truncateText } from 'utils/string';

import { UserEmailBounceModal } from '../modals/UserEmailBounceModal';

const headerCells: HeaderCell[] = [
  {
    sortable: true,
    intl: 'user_details.email_bounces_time'
  },
  {
    sortable: true,
    intl: 'user_details.email_bounces_bounce_type'
  },
  {
    sortable: true,
    intl: 'user_details.email_bounces_bounce_subtype'
  },
  {
    sortable: true,
    intl: 'user_details.email_bounces_diagnostic_info'
  },
  {
    sortable: false,
    intl: 'user_details.email_bounces_details'
  }
];

type Props = {
  data: EmailBounce[];
  isLoading: boolean;
};

export const UserEmailBounceCard: FC<Props> = ({ data, isLoading }) => {
  // State
  const [modalData, setModalData] = useState<EmailBounceAttributes | null>(
    null
  );

  // Actions
  const onClose = useCallback(() => setModalData(null), []);
  const onOpen = useCallback(
    (attributes: EmailBounceAttributes) => () => setModalData(attributes),
    []
  );

  // Table
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }

    // Body rows
    const bodyRows = data.map(({ attributes }) => ({
      time: formatDate(
        parse(
          attributes.timestamp,
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          new Date()
        ).getTime(),
        'yyyy-MM-dd HH:mm'
      ),
      bounceType: attributes.bounceType || '-',
      bounceSubtype: attributes.bounceSubtype || '-',
      diagnosticInfo: truncateText(
        attributes.diagnosticCode,
        userEmailBouncesDiagnosticInfoCharacterLimit
      ),
      button: (
        <IconButton onClick={onOpen(attributes)}>
          <ArrowIcon
            size="small"
            direction="right"
            themeType="grey6"
            transition={false}
          />
        </IconButton>
      )
    }));

    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={bodyRows}
        emptyTextId="user_details.email_bounces_empty"
        sortOrder={SortOrder.Desc}
      />
    );
  }, [onOpen, data, isLoading]);

  return (
    <Card>
      <CardHeader titleIntl="user_details.email_bounces_title" />
      {content}
      <UserEmailBounceModal data={modalData} onClose={onClose} />
    </Card>
  );
};
