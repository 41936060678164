import { FC, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import * as routes from 'router/Routes';
import { ProductAttributes } from 'models';

import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import Loader from 'components/UI/Loader';

type Props = {
  products: ProductAttributes[];
  isLoading?: boolean;
};

const ProductRelationCard: FC<Props> = ({ products, isLoading }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Link to product
  const onClick = useCallback(
    (iprId: string) => {
      navigate(routes.productDetailsLink(iprId), {
        state: { from: pathname }
      });
    },
    [navigate, pathname]
  );

  // Render table
  const table = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }

    // Header
    const headerCells: HeaderCell[] = [
      {
        intl: 'products.product_table_header_image',
        sortable: false
      },
      {
        intl: 'products.product_table_header_title',
        sortable: true
      },
      {
        intl: 'products.product_table_header_pnc',
        sortable: true
      },
      {
        intl: 'products.product_table_header_serial',
        sortable: true
      }
    ];

    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={products.map((product) => ({
          imageUrl: product.imageUrl,
          title: product.modelName
            ? `${product.brand} ${product.modelName}`
            : '–',
          pnc: product.pnc || '–',
          serialNumber: product.serialNumber || '–',
          link: product.iprId
        }))}
        emptyTextId="customers.relations_table_empty"
        onClick={onClick}
      />
    );
  }, [products, isLoading, onClick]);

  return (
    <Card>
      <CardHeader titleIntl="products.title" />
      {table}
    </Card>
  );
};

export default ProductRelationCard;
