import { FC, useCallback, useMemo } from 'react';

import { TermsAndConditionAttributes } from 'models';
import { formatDate } from 'utils/date';
import { extractTitle } from 'utils/serviceContracts';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import { IconType } from 'components/UI/Icon/IconPath';
import Icon from 'components/UI/Icon';
import Loader from 'components/UI/Loader';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import Tag from 'components/UI/Tag';
import IconButton from 'components/UI/IconButton';

import { TagGrid } from 'styles';

type Props = {
  termsAndConditions: TermsAndConditionAttributes[];
  locale: string;
  isLoading?: boolean;
};

const externalLinkIcon = (
  <Icon type={IconType.ExternalLink} themeType="grey6" />
);

const TermsAndConditionsCard: FC<Props> = ({
  termsAndConditions,
  locale,
  isLoading
}) => {
  // Link to open terms and condition pdf in a new window
  const onClick = useCallback((link: string) => {
    window.open(link);
  }, []);

  // Table header cells
  const headerCells = useMemo<HeaderCell[]>(() => {
    // Header
    return [
      {
        sortable: true,
        intl: 'contracts.terms_and_conditions_table_title'
      },
      {
        sortable: true,
        intl: 'contracts.terms_and_conditions_table_languages'
      },
      {
        sortable: true,
        intl: 'contracts.terms_and_conditions_table_created'
      },
      {
        sortable: true,
        intl: 'contracts.terms_and_conditions_table_updated'
      }
    ];
  }, []);

  // Table Rows
  const bodyRows = useMemo(() => {
    return termsAndConditions.map(
      ({ publicationTitle, languages, link, created, updated }) => {
        const createdFormatted = formatDate(created, 'yyyy-MM-dd HH:mm');
        const updatedFormatted = formatDate(updated, 'yyyy-MM-dd HH:mm');
        const title = extractTitle(publicationTitle, languages, locale);
        const rowData = {
          title,
          languages: (
            <TagGrid>
              {languages.length
                ? languages.map((group) => (
                    <Tag key={group}>{group?.toUpperCase()}</Tag>
                  ))
                : '-'}
            </TagGrid>
          ),
          link,
          created: createdFormatted,
          updated: updatedFormatted,
          button: (
            <IconButton onClick={() => onClick(link)}>
              {externalLinkIcon}
            </IconButton>
          )
        };
        return rowData;
      }
    );
  }, [termsAndConditions, locale, onClick]);

  const table = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }
    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={bodyRows}
        emptyTextId="contracts.terms_and_conditions_table_empty"
      />
    );
  }, [isLoading, headerCells, bodyRows]);

  return (
    <Card>
      <CardHeader titleIntl="contracts.terms_and_conditions_title" />
      {table}
    </Card>
  );
};

export default TermsAndConditionsCard;
