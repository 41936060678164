import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { APIError, UserState, User, Pagination, UserFilterData } from 'models';
import * as service from 'services';
import type { RootState } from 'store/store';

export const defaultState: UserState = {
  search: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isLoadingMore: false,
    isError: false,
    error: null,
    filters: {
      isActive: false,
      data: {
        username: null,
        group: null,
        country: null,
        language: null
      }
    },
    pagination: {
      limit: 50,
      offset: 0
    }
  },
  details: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const userSlice = createSlice({
  name: 'user',
  initialState: defaultState,
  reducers: {
    userSearch: (state) => {
      state.search.isSuccess = false;
      state.search.isLoading = true;
      state.search.isError = false;
      state.search.error = null;
    },
    userSearchSuccess: (state, action: PayloadAction<User[]>) => {
      state.search.isSuccess = true;
      state.search.isLoading = false;
      state.search.data = action.payload;
    },
    userSearchFailure: (state, action: PayloadAction<APIError>) => {
      state.search.data = [];
      state.search.isLoading = false;
      state.search.isError = true;
      state.search.error = action.payload;
    },
    loadMore: (state, action: PayloadAction<number>) => {
      state.search.isSuccess = false;
      state.search.isLoadingMore = true;
      state.search.isError = false;
      state.search.error = null;
      state.search.pagination.offset = action.payload;
    },
    loadMoreSuccess: (state, action: PayloadAction<User[]>) => {
      state.search.isSuccess = true;
      state.search.isLoadingMore = false;
      if (state.search.data) {
        state.search.data = [...state.search.data, ...action.payload];
      }
    },
    loadMoreFailure: (state, action: PayloadAction<APIError>) => {
      state.search.isLoadingMore = false;
      state.search.isError = true;
      state.search.error = action.payload;
    },
    setUserSearchFiltersActive: (state, action: PayloadAction<boolean>) => {
      state.search.filters.isActive = action.payload;
    },
    setUserSearchFilters: (
      state,
      action: PayloadAction<Partial<UserFilterData>>
    ) => {
      state.search.filters.data = {
        ...state.search.filters.data,
        ...action.payload
      };
    },
    resetUserSearchState: (state) => {
      state.search = defaultState.search;
    },
    userDetails: (state, action: PayloadAction<boolean>) => {
      state.details.isSuccess = false;
      state.details.isLoading = action.payload;
      state.details.isError = false;
      state.details.error = null;
    },
    userDetailsSuccess: (state, action: PayloadAction<User>) => {
      state.details.isSuccess = true;
      state.details.isLoading = false;
      state.details.data = action.payload;
    },
    userDetailsFailure: (state, action: PayloadAction<APIError>) => {
      state.details.data = null;
      state.details.isLoading = false;
      state.details.isError = true;
      state.details.error = action.payload;
    },
    resetUserDetailsState: (state) => {
      state.details = defaultState.details;
    }
  }
});

// Export selectors
export const userSearchSelector = (state: RootState) => state.user.search;
export const userDetailsSelector = (state: RootState) => state.user.details;

// Export actions
export const {
  userSearch,
  userSearchSuccess,
  userSearchFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
  setUserSearchFiltersActive,
  setUserSearchFilters,
  resetUserSearchState,
  userDetails,
  userDetailsSuccess,
  userDetailsFailure,
  resetUserDetailsState
} = userSlice.actions;

// Export reducer
export const userReducer = userSlice.reducer;

// Export thunk
export function findUsersBySearch(filters: UserFilterData) {
  return async (dispatch: Dispatch) => {
    dispatch(userSearch());
    try {
      const users = await service.findUsersByFilters(filters);
      dispatch(userSearchSuccess(users));
    } catch (e: any) {
      dispatch(userSearchFailure(e));
    }
  };
}

export function loadMoreResults(
  filters: UserFilterData,
  pagination: Pagination
) {
  return async (dispatch: Dispatch) => {
    dispatch(loadMore(pagination.offset as number));
    try {
      const users = await service.findUsersByFilters(filters, pagination);
      dispatch(loadMoreSuccess(users));
    } catch (e: any) {
      dispatch(loadMoreFailure(e));
    }
  };
}

export function findUserById(userId: string, refetch = false) {
  return async (dispatch: Dispatch) => {
    dispatch(userDetails(!refetch));
    try {
      const user = await service.findUserById(userId);
      dispatch(userDetailsSuccess(user));
    } catch (e: any) {
      dispatch(userDetailsFailure(e));
    }
  };
}
