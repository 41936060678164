import { CustomerConsent, CustomerConsentType, ConsentWithType } from 'models';

export const mapConsentAndType = (
  consents: CustomerConsent[],
  types: CustomerConsentType[]
): ConsentWithType[] => {
  const mapped: ConsentWithType[] = [];
  consents.forEach((consent) => {
    const found = types.find(({ id }) => {
      return id === consent.attributes.consentTypeId;
    });
    mapped.push({
      ...consent,
      attributes: {
        ...consent.attributes,
        typePurpose: found ? found.attributes.purpose : '',
        typeName: found ? found.attributes.name : ''
      }
    });
  });

  return mapped;
};
