import { FC, ReactNode } from 'react';
import { Root } from './styled';

type Props = {
  active?: boolean;
  children?: ReactNode;
};

const TableBodyRow: FC<Props> = ({ children, active = false }) => {
  return <Root $active={active}>{children}</Root>;
};

export default TableBodyRow;
