export enum SortOrder {
  Asc = 1,
  Desc = -1
}

export function sortObjectArray<T>(
  arr: T[],
  key: keyof T,
  order: SortOrder = SortOrder.Asc
): T[] {
  return [...arr].sort((a, b) => {
    if (a[key] === undefined || a[key] === null) {
      return 1;
    }
    if (b[key] === undefined || b[key] === null) {
      return -1;
    }
    if (a[key] < b[key]) {
      return -1 * order;
    }
    if (a[key] > b[key]) {
      return 1 * order;
    }
    return 0;
  });
}

export function splitArray<T>(array: T[], size: number): T[][] {
  if (array.length <= size) {
    return [array];
  }
  return [array.slice(0, size), ...splitArray(array.slice(size), size)];
}

export function deleteItemFromArray<T>(array: T[], index: number) {
  const newArray = [...array];
  const [deleted] = newArray.splice(index, 1);
  return { newArray, deleted };
}

export function updateArrayItem<T>(array: T[], index: number, update: T) {
  const newArray = [...array];
  newArray[index] = { ...newArray[index], ...update };
  return newArray;
}

export function getNextListIndex<T>(list: T[], highlighted: T, isUp: boolean) {
  const index = list.indexOf(highlighted);

  if (isUp) {
    return index <= 0 ? 0 : index - 1;
  }
  return index === list.length - 1 ? list.length - 1 : index + 1;
}

export function filterDuplicates<T>(list: T[], key: keyof T): T[] {
  const existingValues = new Set<string>();

  return list.filter((item) => {
    const value = item[key] as string;

    if (!existingValues.has(value)) {
      existingValues.add(value);
      return true;
    }
    return false;
  });
}

export function isEmptyArray(items: any[] | null | undefined): boolean {
  return !Array.isArray(items) || items.length === 0;
}
