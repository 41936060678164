import styled from 'styled-components';

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailTitle = styled.h4`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey6};
  text-wrap: nowrap;
  margin: 0 16px 4px 0;
`;

export const DetailValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey5};
  flex: 1;

  p {
    margin: 0 0 4px;
  }
`;
