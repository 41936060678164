import { APIError } from 'models/errors';
import { Pagination } from 'models/common';
import { ProductRegistration } from 'models/product';
import { RequestMeta } from 'models/meta';

export type CustomerEmailAddress = {
  emailAddress: string;
};

export enum PhoneType {
  Home = 'Home',
  Work = 'Work',
  Mobile = 'Mobile'
}

export type CustomerPhoneContact = {
  phoneNumber: string;
  phoneType?: PhoneType;
  isVerified?: boolean;
  updated?: number;
};

export type CustomerEContact = {
  emailContacts: CustomerEmailAddress[];
  phoneContacts: CustomerPhoneContact[];
};

export type CustomerAddress = {
  city?: string;
  street?: string;
  zipCode?: string;
  country?: string;
};

export type CustomerDIAMUser = {
  username: string;
  firstName: string;
  lastName: string;
  country: string;
  language: string;
  groups: string[];
  iamUserId: string;
};

export type CustomerAttributes = {
  customerId: string;
  ceid: string;
  firstName: string;
  lastName: string;
  country: string;
  language: string;
  type: string;
  companyName?: string;
  vatNumber?: string;
  dunsNumber?: string;
  primaryContact?: PrimaryContact;
  locationAddress: CustomerAddress;
  shippingAddress: CustomerAddress;
  billingAddress: CustomerAddress;
  eContact: CustomerEContact;
  userAccount: boolean;
  salesCompanyCodes: string[];
  users: CustomerDIAMUser[];
};

export type DealerAddress = {
  coAddress: string;
  name: string;
  street: string;
  zipCity: string;
  country?: string;
};

export type DealerAttributes = {
  iprId: string | null;
  name: string | null;
  address: DealerAddress | null;
  companyCode: string | null;
  customerNumber: string | null;
};

export type Dealer = {
  id: string;
  type: string;
  attributes: DealerAttributes;
};

export type PrimaryContact = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
};

export type CustomerUpdate = {
  firstName?: string;
  lastName?: string;
  country?: string;
  language?: string;
  type?: string;
  billingAddress?: CustomerAddress;
  locationAddress?: CustomerAddress;
  shippingAddress?: CustomerAddress;
  eContact?: {
    phoneContacts: CustomerPhoneContact[];
  };
};

export type OrganizationCustomerCreate = {
  companyName: string;
  vatNumber?: string;
  dunsNumber: string;
  country: string;
  language: string;
  type: CustomerType.OrganizationCustomer;
  companyRegistrationNumberVerified: boolean;
  userAccount: boolean;
  primaryContact: PrimaryContact;
  eContact: CustomerEContact;
  manuallyVerified?: boolean;
};

export interface Customer {
  id: string;
  type: string;
  attributes: CustomerAttributes;
}

export interface CustomerResponse {
  data: Customer;
}

export interface CustomersResponse {
  data: Customer[];
}

export interface CustomerDetailsAttributes {
  customer: CustomerAttributes;
  productRegistrations: ProductRegistration[];
  nextCursor: string | null;
}

export interface CustomerDetailsResponse {
  data: {
    type: string;
    attributes: CustomerDetailsAttributes;
  };
}

export type ChangeEmailBody = {
  newEmail: string;
  oldEmail: string;
  meta?: RequestMeta;
};

export type UpdateCustomer = {
  isSuccess: boolean;
  isLoading: boolean;
  error: APIError | null;
};

export type CreateCustomer = {
  data: CustomerAttributes | null;
  isSuccess: boolean;
  isLoading: boolean;
  error: APIError | null;
};

export type ChangeEmail = {
  newEmail: string | null;
  isSuccess: boolean;
  isLoading: boolean;
  error: APIError | null;
};

export type DeleteRegistrations = {
  timeStamp: number | null;
  isLoading: boolean;
  error: APIError | null;
};

export type ConfirmDeleteCustomer = {
  data: Customer[] | null;
  isSuccess: boolean;
  isLoading: boolean;
  error: APIError | null;
};

export type CustomerList = {
  data: Customer[] | null;
  isLoading: boolean;
  error: APIError | null;
  filters: {
    active: boolean;
    customerType: CustomerType;
  };
};

export type CustomerDetails = {
  data: CustomerDetailsAttributes | null;
  pagination: Pagination;
  isLoading: boolean;
  error: APIError | null;
  deleteRegistrations: DeleteRegistrations;
  changeEmail: ChangeEmail;
  update: UpdateCustomer;
  confirmDeleteCustomer: ConfirmDeleteCustomer;
};

export type CustomerState = {
  create: CreateCustomer;
  list: CustomerList;
  details: CustomerDetails;
};

export enum CustomerFilterAttribute {
  Email = 'email',
  CustomerType = 'customerType',
  CustomerId = 'customerId',
  DunsNumber = 'dunsNumber',
  CompanyName = 'companyName',
  CustomerNumber = 'customerNumber',
  SalesCompanyCode = 'salesCompanyCode',
  PrimaryContactEmail = 'primaryContactEmail',
  OnboardingSource = 'onboardingSource'
}

export type CustomerFilter = {
  attribute: CustomerFilterAttribute;
  value: string;
};

export enum CustomerType {
  IndividualCustomer = 'IndividualCustomer',
  OrganizationCustomer = 'OrganizationCustomer'
}
