import { ServiceContractAttributes, StatusColor } from 'models';

export function fallbackTitle(publicationTitle: Record<string, string>) {
  return publicationTitle.en ?? '-';
}

export function getTranslatedTitles(
  languages: string[],
  publicationTitle: Record<string, string>
) {
  return languages.filter((langCode) =>
    Object.prototype.hasOwnProperty.call(
      publicationTitle,
      langCode.toLowerCase()
    )
  );
}

export function extractTitle(
  publicationTitle: Record<string, string>,
  languages: string[],
  locale: string
) {
  if (languages.includes('ANY')) {
    return fallbackTitle(publicationTitle);
  }

  const availableTranslatedTitles = getTranslatedTitles(
    languages,
    publicationTitle
  );

  // If there is only one available translated language
  // Then select that one
  const selectedLanguage =
    availableTranslatedTitles.length === 1
      ? availableTranslatedTitles[0]
      : locale;

  // If there is only one available translated title
  // then select that one
  // If there are more than two available translated titles
  // Then select the translated title that matches browser locale
  // Otherwise fallbackTitle()
  return (
    publicationTitle[selectedLanguage?.toLowerCase()] ??
    fallbackTitle(publicationTitle)
  );
}

export function isNotActiveOrPending(
  contracts: ServiceContractAttributes[]
): boolean {
  return contracts.every(({ status }) => {
    return status !== 'ACTIVE' && status !== 'PENDING';
  });
}

export function getContractStatusColors(status: string): StatusColor {
  switch (status) {
    case 'ACTIVE':
      return {
        background: 'success',
        color: 'onPrimary'
      };
    case 'PENDING':
    case 'ON_HOLD':
      return {
        background: 'warning',
        color: 'onWarning'
      };
    case 'EXPIRED':
    case 'CANCELLED':
      return {
        background: 'error',
        color: 'onPrimary'
      };
    default:
      return {
        background: 'grey3',
        color: 'grey6'
      };
  }
}
