import { ServiceContractAttributes } from 'models/contracts';
import { CustomerAttributes, DealerAttributes } from 'models/customers';
import { APIError } from 'models/errors';

export type Registration = {
  id: string;
  productId: string;
  customerId: string;
  iprId: string;
  ownerType: string;
  relationshipType: string;
  dataReliability: string;
  productType: string;
  createdForSystem: string;
  created: number;
  dealer: DealerData;
};

export type ProductRegistration = {
  customer: CustomerAttributes;
  product: ProductAttributes;
  warranty: WarrantyAttributes | null;
  registrations: Registration[];
  serviceContracts: ServiceContractAttributes[];
  dealers: DealerAttributes[];
};

export type WarrantyAttributes = {
  expireDate: string;
  extendedWarrantyPeriod: number;
  iprId: string;
  soldDate: string;
  source: string | null;
  verified: boolean;
  verifiedBy: string | null;
  verifiedWhen: string | null;
  warrantyPeriod: number;
  extendedWarrantyCampaigns: object[];
  createDate: string | null;
  updateDate: string | null;
  companyCode: string | null;
  customerNo: string | null;
  customerType: string | null;
};

export type Warranty = {
  id: string;
  type: string;
  attributes: WarrantyAttributes;
};

export type WarrantyResponse = {
  data: Warranty;
};

export type CreateWarranty = {
  soldDate: string;
  consumerType: string;
  companyCode: string;
  customerNo: string;
};

export type ProductAttributes = {
  brand: string;
  description: string;
  husqvarnaId: string;
  imageId: string;
  imageUrl: string;
  iprId: string;
  modelName: string;
  pnc: string;
  productCategory: string;
  serialNumber: string;
  productType: string;
  localIdGardena?: string;
};

export type ProductList = {
  data: ProductAttributes[] | null;
  isLoading: boolean;
  error: APIError | null;
  filters: {
    active: boolean;
  };
};

export type ProductDetailsState = {
  data: ProductDetails | null;
  isLoading: boolean;
  error: APIError | null;
};

export type FactoryPinState = {
  data: string | null;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type ProductState = {
  list: ProductList;
  details: ProductDetailsState;
  tree: ProductTreeState;
  factoryPin: FactoryPinState;
};

export type DealerData = {
  companyCode: string;
  customerNumber: string;
};

export type RegisterFormFields = {
  companyCode: string;
  customerNumber: string;
  soldDate: string;
};

export type ProductRegistrationState = {
  data: RegisterProductListItem | null;
  params: ProductFilterObject | null;
  isSuccess: boolean;
  isLoading: boolean;
  error: APIError | null;
};

export type Product = {
  type: string;
  attributes: {
    products: ProductAttributes[];
  };
};

export type FactoryPin = {
  id: string;
  type: string;
  attributes: {
    factorypin: string;
  };
};

export enum ProductFilterAttribute {
  PNC = 'pnc',
  SN = 'serialNumber',
  IprId = 'iprId',
  Email = 'email',
  LocalIdGardena = 'localIdGardena'
}

export type ProductFilter = {
  attribute: ProductFilterAttribute;
  value: string;
};

export type ProductFilterObject = {
  pnc: string;
  serialNumber: string;
  husqvarnaId?: string;
  localIdGardena?: string;
};

export type RegisterProductData = {
  pnc?: string;
  serialNumber?: string;
  husqvarnaId?: string;
  createInIpr?: boolean;
};

export interface RegisterProductListItem extends RegisterProductData {
  imageId?: string;
  imageUrl?: string;
  title?: string;
  iprId?: string;
  productCategory?: string;
}

export interface RegisterProductsCustomer {
  customerId: string;
  product: RegisterProductData;
  dealer: DealerData;
  soldDate: number;
}

export interface ProductDetails {
  product: ProductAttributes;
  warranty: WarrantyAttributes | null;
  productRegistrations: ProductRegistration[];
  serviceContracts: ServiceContractAttributes[];
  dealers: DealerAttributes[];
}

export type ProductDetailsResponse = {
  data: {
    type: string;
    attributes: ProductDetails;
  };
};

export type ProductResponse = {
  data: {
    type: string;
    attributes: ProductAttributes;
  };
};

export interface ProductFactoryPinResponse {
  data: FactoryPin;
  errors?: APIError[];
}

export type CreateWarrantyState = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type RemoveWarrantyState = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type WarrantyState = {
  data: Warranty | null;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type WarrantyConditionState = {
  warranty: WarrantyState;
  createWarranty: CreateWarrantyState;
  removeWarranty: RemoveWarrantyState;
};

export type ProductTreeAttributes = {
  iprId: string;
  pnc: string;
  serialNumber: string;
  husqvarnaId: string;
  name: string;
  type: string;
  imageId: string | null;
  imageUrl: string | null;
  position: string;
  level: number;
  children: ProductTreeAttributes[];
};

export type ProductTree = {
  id: string;
  type: string;
  attributes: ProductTreeAttributes;
};

export type ProductTreeResponse = {
  data: ProductTree;
};

export type ProductTreeState = {
  data: ProductTreeAttributes | null;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};
