import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';

import ArrowIcon from 'components/UI/Icon/ArrowIcon';

import {
  Root,
  Divider,
  ContentContainer,
  Title,
  NavButtonContainer,
  LeftColumn,
  RightColumn,
  TitleContainer,
  Description
} from './styled';

type Props = {
  children?: ReactNode;
  divider?: boolean;
  title?: string;
  description?: string;
  titleIntl?: string;
  descriptionIntl?: string;
  navUrl?: string;
};

const CardHeader: FC<Props> = ({
  children,
  divider = false,
  title,
  description,
  titleIntl,
  descriptionIntl,
  navUrl
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // Back
  const onBack = useCallback(() => {
    if (navUrl) {
      navigate(state?.from ?? navUrl);
    }
  }, [navigate, state, navUrl]);

  // Title
  const cardTitle = useMemo(() => {
    if (navUrl) {
      return (
        <NavButtonContainer onClick={onBack}>
          <ArrowIcon direction="left" themeType="grey5" />
          <Title>
            <FormattedMessage id={state?.from ? 'misc.back' : titleIntl} />
          </Title>
          {descriptionIntl && <span>{descriptionIntl}</span>}
        </NavButtonContainer>
      );
    }

    if (titleIntl) {
      return (
        <TitleContainer>
          <Title>
            <FormattedMessage id={titleIntl} />
          </Title>
          {descriptionIntl && (
            <Description>
              <FormattedMessage id={descriptionIntl} />
            </Description>
          )}
        </TitleContainer>
      );
    }

    return (
      <TitleContainer>
        <Title>{title}</Title>
        {descriptionIntl && <Description>{description}</Description>}
      </TitleContainer>
    );
  }, [navUrl, state, title, description, titleIntl, descriptionIntl, onBack]);

  return (
    <Root $bottomMargin={divider}>
      <ContentContainer>
        <LeftColumn>{cardTitle}</LeftColumn>
        <RightColumn>{children}</RightColumn>
      </ContentContainer>
      {divider && <Divider />}
    </Root>
  );
};

export default CardHeader;
