import styled from 'styled-components';
import { breakpoints } from 'styles';

export const NotFoundCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  border-radius: 16px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 24px;

  h3 {
    color: ${({ theme }) => theme.colors.grey9};
    margin: 0 0 16px;
  }

  p {
    margin: 0;
  }
`;

export const ProductCard = styled.div`
  display: flex;
  gap: 24px;
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  border-radius: 16px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    align-items: center;
  }

  h3 {
    color: ${({ theme }) => theme.colors.grey9};
    margin: 0 0 16px;
  }
`;

export const ProductImage = styled.img`
  height: 96px;

  @media (max-width: ${breakpoints.s}px) {
    max-width: 160px;
    height: auto;
  }
`;

export const RegisterProductRow = styled.div`
  display: flex;
  gap: 16px;

  img {
    max-height: 64px;
  }
`;

export const ProductContent = styled.div`
  width: 100%;
`;

export const Grid = styled.div`
  display: flex;
  gap: 16px;
`;

export const Results = styled.div`
  margin: 48px 0;
`;

export const ModalContent = styled.div`
  overflow-y: auto;
`;

export const NoProductImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grey0};
  border: 1px solid ${({ theme }) => theme.colors.grey3};
  min-width: 96px;
  height: 96px;
  border-radius: 8px;
`;
