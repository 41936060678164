import { FC, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import * as routes from 'router/Routes';
import { CustomerAttributes, Registration } from 'models';
import { isOrganizationCustomer } from 'utils/customer';
import { isEmptyArray } from 'utils/array';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import SortableTable from 'components/UI/Table/SortableTable';
import Loader from 'components/UI/Loader';
import Tag from 'components/UI/Tag';

import { Registrations } from './styled';

type CustomerRegistration = {
  customer: CustomerAttributes;
  registrations?: Registration[];
};

type Props = {
  data?: CustomerRegistration[];
  descriptionIntl?: string;
  isLoading?: boolean;
};

const CustomerRelationCard: FC<Props> = ({
  data = [],
  isLoading,
  descriptionIntl
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Link to customer
  const onClick = useCallback(
    (customerId: string) => {
      navigate(routes.customerDetailsLink(customerId), {
        state: { from: pathname }
      });
    },
    [navigate, pathname]
  );

  // Render relation
  const renderRegistrations = useCallback((registrations?: Registration[]) => {
    if (!registrations || isEmptyArray(registrations)) {
      return '-';
    }
    return (
      <Registrations>
        {registrations.map((item) => (
          <Tag key={item.id}>{item.relationshipType}</Tag>
        ))}
      </Registrations>
    );
  }, []);

  // Content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }
    return (
      <SortableTable
        headerCells={[
          {
            sortable: true,
            intl: 'customers.relations_table_name'
          },
          {
            sortable: true,
            intl: 'customers.relations_table_email'
          },
          {
            sortable: true,
            intl: 'customers.relations_table_relations'
          }
        ]}
        bodyRows={data.map(({ customer, registrations }) => {
          const {
            companyName,
            customerId,
            eContact: { emailContacts },
            firstName,
            lastName
          } = customer;

          const name = isOrganizationCustomer(customer)
            ? companyName
            : `${firstName} ${lastName}`;

          return {
            name: name || '-',
            email: emailContacts?.length ? emailContacts[0].emailAddress : '-',
            link: customerId,
            registrations: renderRegistrations(registrations)
          };
        })}
        emptyTextId="customers.relations_table_empty"
        onClick={onClick}
      />
    );
  }, [data, isLoading, onClick, renderRegistrations]);

  return (
    <Card>
      <CardHeader
        titleIntl="customers.title"
        descriptionIntl={descriptionIntl}
      />
      {content}
    </Card>
  );
};

export default CustomerRelationCard;
