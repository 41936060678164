import { FC, ReactNode, useCallback } from 'react';

import Tooltip from 'components/UI/Tooltip';
import { ContentContainer, Image, Root, Content } from './styled';

type Props = {
  children?: ReactNode;
  imageUrl?: string;
  onClick?: () => void;
  disabled?: string | boolean;
  indent?: number;
  isPressable?: boolean;
};

const TableDataCell: FC<Props> = ({
  children,
  imageUrl,
  onClick,
  indent = 0,
  disabled = false,
  isPressable = false
}) => {
  // Handle cell click
  const onCellClick = useCallback(() => {
    if (!disabled && onClick) {
      onClick();
    }
  }, [onClick, disabled]);

  // Render body
  const renderBody = useCallback(
    (body: ReactNode) => {
      if (!disabled || typeof disabled !== 'string') {
        return body;
      }
      return <Tooltip paragraph={disabled}>{body}</Tooltip>;
    },
    [disabled]
  );

  return (
    <Root
      $isPressable={isPressable}
      $indent={indent}
      $padding={!imageUrl}
      disabled={!!disabled}
      onClick={onCellClick}
    >
      {renderBody(
        <ContentContainer $isPressable={isPressable}>
          {imageUrl && <Image src={imageUrl} alt="Cell image" />}
          <Content>{children}</Content>
        </ContentContainer>
      )}
    </Root>
  );
};

export default TableDataCell;
