import { FC, useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from 'components/UI/Button';
import InputField from 'components/UI/InputField';
import SelectField, { OptionType } from 'components/UI/SelectField';
import { useDisplayNames } from 'hooks/useDisplayNames';
import { MetaSalesCompanyCode, RegisterFormFields } from 'models';
import { formatDate } from 'utils/date';
import { getSalesCompanyCodeOptions } from 'utils/meta';

import { Form, Row, ButtonGrid } from './styled';

type Props = {
  onClose: () => void;
  onRegister: (values: RegisterFormFields) => void;
  salesCompanyCodes: MetaSalesCompanyCode[];
  disabled: boolean;
};

const RegistrationForm: FC<Props> = ({
  onRegister,
  onClose,
  salesCompanyCodes,
  disabled
}) => {
  const intl = useIntl();
  const countryDisplayNames = useDisplayNames('region');

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm();

  // Watch fields
  const [companyCode, soldDate] = watch(['companyCode', 'soldDate']);

  // Submit
  const onSubmit: SubmitHandler<any> = useCallback(onRegister, [onRegister]);

  // Set date
  const setDate = useCallback(
    (name: string) => (date: Date) => {
      setValue(name, formatDate(date.getTime(), 'yyyy-MM-dd'), {
        shouldDirty: true
      });
    },
    [setValue]
  );

  // Select option
  const onSelect = useCallback(
    (name: string) => (option: OptionType) =>
      setValue(name, option.value, { shouldDirty: true }),
    [setValue]
  );

  // Sales company Countries
  const sccOptions: OptionType[] = useMemo(() => {
    return getSalesCompanyCodeOptions(salesCompanyCodes, countryDisplayNames);
  }, [countryDisplayNames, salesCompanyCodes]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <SelectField
          name="companyCode"
          register={register('companyCode', {
            required: {
              value: true,
              message: intl.formatMessage({ id: 'input.required' })
            }
          })}
          onSelect={onSelect('companyCode')}
          label={intl.formatMessage({
            id: 'modal.register_products_input_company_code'
          })}
          options={sccOptions}
          value={companyCode}
          error={errors.companyCode}
          enableSearch
        />
        <InputField
          description="modal.register_products_input_dealer_no"
          register={register('customerNumber', {
            required: {
              value: true,
              message: 'input.required'
            },
            maxLength: {
              value: 20,
              message: 'input.wrong_length'
            }
          })}
          error={errors.customerNumber}
        />
      </Row>
      <Row>
        <InputField
          type="date"
          description={'modal.register_products_input_sold_date'}
          register={register('soldDate', {
            required: {
              value: true,
              message: 'input.required'
            }
          })}
          setDate={setDate('soldDate')}
          defaultValue={soldDate}
          error={errors.soldDate}
        />
      </Row>
      <ButtonGrid>
        <Button
          onClick={onClose}
          backgroundColor="transparent"
          color="primary"
          marginRight
        >
          <FormattedMessage id="modal.btn_cancel" />
        </Button>
        <Button disabled={disabled} submit>
          <FormattedMessage id="modal.btn_register" />
        </Button>
      </ButtonGrid>
    </Form>
  );
};

export default RegistrationForm;
