import styled from 'styled-components';

type MarginProps = {
  $bottomMargin: boolean;
};

export const Root = styled.div<MarginProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ $bottomMargin }) => ($bottomMargin ? '20px' : '0')};
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 16px;
`;

export const LeftColumn = styled.div`
  display: flex;
`;

export const RightColumn = styled.div`
  flex: 1;
  text-align: right;
`;

export const NavButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    margin-right: 8px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.grey5};
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
`;

export const Description = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey6};
  font-size: 14px;
`;

export const Divider = styled.div`
  height: 0;
  width: 100%;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.grey3};
`;
