import { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  fetchUserStatistics,
  fetchUserStatisticsCsvExport,
  resetUserStatisticsState,
  userStatisticsSelector
} from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import Card from 'components/UI/Card';
import DropdownMenu from 'components/UI/DropdownMenu';
import EmptyState from 'components/UI/EmptyState';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Loader from 'components/UI/Loader';
import Heading from 'components/UI/Heading';
import CardHeader from 'components/UI/Card/CardHeader';
import UserStatisticsChart from 'components/users/UserStatisticsChart';
import UserStatisticsTable from 'components/users/UserStatisticsTable';
import { downloadFile } from 'utils/exporting';

const UserStatisticsPage: FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { list, exports } = useAppSelector(userStatisticsSelector);
  const { data, error, isLoading } = list;

  // Fetch statistics data
  const fetchData = useCallback(() => {
    dispatch(fetchUserStatistics());
  }, [dispatch]);

  // Mount fetch statistics
  useEffect(() => {
    fetchData();

    return () => {
      dispatch(resetUserStatisticsState());
    };
  }, [dispatch, fetchData]);

  // Export CSV
  useEffect(() => {
    if (exports.data) {
      downloadFile(exports.data, 'user_statistics');
    }
  }, [dispatch, exports]);

  // Get export data
  const onExport = useCallback(() => {
    if (!exports.isLoading) {
      dispatch(fetchUserStatisticsCsvExport());
    }
  }, [dispatch, exports.isLoading]);

  // Menu
  const menu = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: 'user_statistics.export'
        }),
        onClick: () => onExport()
      }
    ];
  }, [intl, onExport]);

  // Loading
  if (isLoading) {
    return <Loader center />;
  }

  // Not found
  if (!data?.length || error) {
    return (
      <EmptyState icon={IconType.Cross} padding>
        <FormattedMessage id="user_statistics.not_found" />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <Heading>
        <FormattedMessage id="user_statistics.title" />
      </Heading>
      <Card>
        <CardHeader titleIntl="user_statistics.top_services_title" />
        <UserStatisticsChart statistics={data} />
      </Card>
      <Card>
        <CardHeader titleIntl="user_statistics.table_title">
          <DropdownMenu menu={menu}>
            <IconButton>
              <Icon type={IconType.More} themeType="grey6" />
            </IconButton>
          </DropdownMenu>
        </CardHeader>
        <UserStatisticsTable statistics={data} />
      </Card>
    </Fragment>
  );
};

export default UserStatisticsPage;
