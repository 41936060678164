import { FC, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';

import * as routes from 'router/Routes';
import { ServiceContractAttributes } from 'models';
import { formatDate } from 'utils/date';
import { getContractStatusColors } from 'utils/serviceContracts';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import Tag from 'components/UI/Tag';
import Loader from 'components/UI/Loader';

// Header cells
const headerCells: HeaderCell[] = [
  {
    sortable: true,
    intl: 'product_details.contracts_table_id'
  },
  {
    sortable: true,
    intl: 'product_details.contracts_table_type'
  },
  {
    sortable: true,
    intl: 'product_details.contracts_table_status'
  },
  {
    sortable: true,
    intl: 'product_details.contracts_table_start_date'
  },
  {
    sortable: true,
    intl: 'product_details.contracts_table_end_date'
  }
];

type Props = {
  contracts?: ServiceContractAttributes[];
  isLoading: boolean;
  descriptionIntl?: string;
};

const ProductContractCard: FC<Props> = ({
  contracts = [],
  descriptionIntl,
  isLoading
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Click
  const onClick = useCallback(
    (id: string) => {
      navigate(routes.serviceContractDetailsLink(id), {
        state: { from: pathname }
      });
    },
    [navigate, pathname]
  );

  // Status
  const renderStatus = useCallback((status: string) => {
    const { background, color } = getContractStatusColors(status);
    return (
      <Tag background={background} color={color}>
        {status}
      </Tag>
    );
  }, []);

  // Content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }
    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={contracts.map((contract) => ({
          id: contract.id,
          type: contract.type,
          status: renderStatus(contract.status),
          startDate: formatDate(contract.startDate, 'yyyy-MM-dd'),
          endDate: formatDate(contract.endDate, 'yyyy-MM-dd'),
          link: contract.id
        }))}
        emptyTextId="product_details.contracts_not_found"
        onClick={onClick}
      />
    );
  }, [contracts, isLoading, renderStatus, onClick]);

  return (
    <Card>
      <CardHeader
        titleIntl="product_details.contracts_title"
        descriptionIntl={descriptionIntl}
      />
      {content}
    </Card>
  );
};

export default ProductContractCard;
